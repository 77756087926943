import React from 'react'
import { graphql } from 'gatsby'
import { mapper } from '../common/utils'
import { Heading } from '@roar/components'
import { Box } from 'rebass/styled-components'

const PROPS_MAP = {
  heading: ['heading'],
  as: ['as'],
  size: ['size'],
  style: ['style'],
}

const HeadingWrapper = (props) => {
  const { heading, as, size, style } = mapper(props, PROPS_MAP)

  return (
    <>
      {heading && (
        <Box {...style}>
          <Heading className="customHeading" as={as} size={size} html={heading} />
        </Box>
      )}
    </>
  )
}

export default HeadingWrapper

export const HEADING_FIELDS = graphql`
  fragment HeadingFields on ContentfulHeading {
    id
    contentful_id
    heading
    as
    size
    style {
      mb
      mt
    }
  }
`
